import { EnvironmentUrls } from 'Roblox';

const { apiGatewayUrl } = EnvironmentUrls;

const getUpsellCardTypeUrlConfig = () => ({
  retryable: false,
  withCredentials: true,
  url: `${apiGatewayUrl}/upsellCard/type`
});

// eslint-disable-next-line import/prefer-default-export
export { getUpsellCardTypeUrlConfig };
