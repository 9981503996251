import React, { useEffect, useState } from 'react';
import { HomePageUpsellCardService, UpsellService } from 'Roblox';
import PropTypes from 'prop-types';
import HomePageUpsellCard from '../components/HomePageUpsellCard';
import isCardTypeSupported from '../utils/upsellCardUtils';
import { UpsellCardType } from '../constants/upsellCardConstants';
import {
  contactMethodPromptOrigins,
  contactMethodPromptSections
} from '../constants/upsellCardEventStreamConstants';

function HomePageUpsellCardContainer({ translate }) {
  const { ContactMethodMandatoryEmailPhone } = UpsellCardType;
  const [upsellCardContext, setUpsellCardContext] = useState(null);

  useEffect(() => {
    const updateUpsellCardContext = async () => {
      try {
        const context = await HomePageUpsellCardService.getHomePageUpsellCardVariation();
        setUpsellCardContext(context);
      } catch (error) {
        console.error(`Error getting the upsell card variation ${error}`);
        setUpsellCardContext(null);
      }
    };
    updateUpsellCardContext();
  });

  useEffect(() => {
    if (upsellCardContext === ContactMethodMandatoryEmailPhone) {
      UpsellService?.renderContactMethodPromptModal({
        origin: contactMethodPromptOrigins.homepage,
        section: contactMethodPromptSections.mandatory
      });
    }
  }, [upsellCardContext]);

  if (isCardTypeSupported(upsellCardContext)) {
    return <HomePageUpsellCard translate={translate} cardType={upsellCardContext} />;
  }
  return null;
}

HomePageUpsellCardContainer.propTypes = {
  translate: PropTypes.func.isRequired
};

export default HomePageUpsellCardContainer;
