import Roblox from 'Roblox';
import { ready } from 'core-utilities';
import React from 'react';
import { render } from 'react-dom';
import App from './App';
import { rootElementId } from './app.config';
import '../../../css/homePageUpsellCard/homePageUpsellCard.scss';
import { getHomePageUpsellCardVariation } from './services/accountInfoService';

// Expose service to internal apps
Roblox.HomePageUpsellCardService = {
  getHomePageUpsellCardVariation
};

function renderApp() {
  const entryPoint = document.getElementById(rootElementId);
  if (entryPoint) {
    const context = entryPoint.getAttribute('context');
    if (context !== null) {
      render(<App context={context} />, entryPoint);
    } else {
      render(<App />, entryPoint);
    }
  } else {
    // Recursively call renderApp if target div not found
    // Callback will be triggered before every repaint
    window.requestAnimationFrame(renderApp);
  }
}

ready(() => {
  renderApp();
});
