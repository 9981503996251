import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-style-guide';
import {
  UpsellCardContent,
  UpsellCardButtonGroup,
  UpsellCardTitle,
  UpsellCardImageClass,
  UpsellCardEventSection,
  UpsellCardButtonOrientations
} from '../constants/upsellCardConstants';
import { sendEvent } from '../services/accountInfoService';
import { events } from '../constants/upsellCardEventStreamConstants';

function HomePageUpsellCard({ translate, cardType, origin }) {
  const [upsellHidden, setUpsellHidden] = useState(false);
  const eventLogSection = UpsellCardEventSection[cardType];

  useEffect(() => {
    sendEvent(events.cardShown, origin, cardType, eventLogSection);
  }, []);

  const primaryButtonConfig = UpsellCardButtonGroup[cardType]?.primaryButton;

  const primaryButton = !primaryButtonConfig ? null : (
    <Button
      className='btn-primary-md'
      id='upsell-card-primary-button'
      onClick={() => {
        sendEvent(
          events.buttonClick,
          origin,
          cardType,
          eventLogSection,
          primaryButtonConfig.buttonClickBtnLog
        );
        primaryButtonConfig.onClick(shouldHide => {
          setUpsellHidden(shouldHide);
        });
      }}>
      {translate(primaryButtonConfig.text)}
    </Button>
  );

  const secondaryButtonConfig = UpsellCardButtonGroup[cardType]?.secondaryButton;
  const secondaryButton = !secondaryButtonConfig ? null : (
    <Button
      className='btn-secondary-md'
      id='upsell-card-secondary-button'
      onClick={() => {
        sendEvent(
          events.buttonClick,
          origin,
          cardType,
          eventLogSection,
          secondaryButtonConfig.buttonClickBtnLog
        );
        secondaryButtonConfig.onClick(shouldHide => {
          setUpsellHidden(shouldHide);
        });
      }}>
      {translate(secondaryButtonConfig.text)}
    </Button>
  );

  const orientation =
    UpsellCardButtonGroup[cardType]?.buttonStackOrientation ??
    UpsellCardButtonOrientations.horizontal;

  const btnGroup = (
    <ol
      className={
        orientation === UpsellCardButtonOrientations.horizontal
          ? 'upsell-card-horizontal-button-list'
          : 'upsell-card-vertical-button-list'
      }>
      <li> {primaryButton} </li>
      <li> {secondaryButton} </li>
    </ol>
  );

  const textContentGroup = (
    <div className='upsell-card-text-content-group'>
      {!UpsellCardTitle[cardType] ? null : (
        <div className='upsell-card-title'> {translate(UpsellCardTitle[cardType])} </div>
      )}
      <div className='upsell-card-content'> {translate(UpsellCardContent[cardType])} </div>
    </div>
  );

  const iconComponent = UpsellCardImageClass[cardType] ? (
    <div className={`home-page-upsell-card-image ${UpsellCardImageClass[cardType]}`} />
  ) : null;

  return upsellHidden ? null : (
    <div className='home-page-upsell-card-banner-container'>
      {iconComponent}
      <div className='banner-content-container'>{textContentGroup}</div>
      <div className='add-email-btn-container'>{btnGroup}</div>
      <div id='facebookSunsetModal-container' />
    </div>
  );
}

HomePageUpsellCard.defaultProps = {
  origin: 'homepage'
};

HomePageUpsellCard.propTypes = {
  translate: PropTypes.func.isRequired,
  cardType: PropTypes.string.isRequired,
  origin: PropTypes.string
};

export default HomePageUpsellCard;
