import { FacebookSunsetService, UpsellService } from 'Roblox';

// TODO @wlu as the entropy of upsell card types increase, we should move over
// to having a render function that takes in an interface where relevant parameters
// are passed in, rather than being defined in this file of constants.
const UpsellCardType = {
  ContactMethodEmail: 'ContactMethodEmail',
  ContactMethodPhoneNumber: 'ContactMethodPhoneNumber',
  ContactMethodPhoneNumberEmailHorizontalLayout: 'ContactMethodPhoneNumberEmailHorizontalLayout',
  ContactMethodPhoneNumberEmailHorizontalLayoutAltContent1:
    'ContactMethodPhoneNumberEmailHorizontalLayoutAltContent1',
  ContactMethodPhoneNumberEmailVerticalLayout: 'ContactMethodPhoneNumberEmailVerticalLayout',
  FacebookSunset: 'FacebookSunset',
  ContactMethodMandatoryEmailPhone: 'ContactMethodMandatoryEmailPhone'
};

const UpsellCardTitle = {
  ContactMethodEmail: '',
  ContactMethodPhoneNumber: 'Label.DontGetLockedOut',
  ContactMethodPhoneNumberEmailHorizontalLayout: 'Label.DontGetLockedOut',
  ContactMethodPhoneNumberEmailHorizontalLayoutAltContent1: 'Heading.FinishAccountSetup',
  ContactMethodPhoneNumberEmailVerticalLayout: 'Label.DontGetLockedOut',
  FacebookSunset: ''
};

const UpsellCardContent = {
  ContactMethodEmail: 'Description.HomePageUpsellCardAddEmailText',
  ContactMethodPhoneNumber: 'Description.HomePageUpsellCardAddPhoneText',
  ContactMethodPhoneNumberEmailHorizontalLayout: 'Label.RecoverYourAccount',
  ContactMethodPhoneNumberEmailHorizontalLayoutAltContent1: 'Description.ContactMethodAccessLoss',
  ContactMethodPhoneNumberEmailVerticalLayout: 'Label.RecoverYourAccount',
  FacebookSunset: 'Description.FacebookSetPasswordUpsellText'
};

const UpsellCardEventContext = {
  ContactMethodEmail: 'homePageUpsellCard',
  ContactMethodPhoneNumber: 'homePageUpsellCard',
  ContactMethodPhoneNumberEmailHorizontalLayout: 'homePageUpsellCard',
  ContactMethodPhoneNumberEmailHorizontalLayoutAltContent1: 'homePageUpsellCard',
  ContactMethodPhoneNumberEmailVerticalLayout: 'homePageUpsellCard',
  FacebookSunset: 'facebookSunsetCard'
};

const UpsellCardEventSection = {
  ContactMethodEmail: 'email',
  ContactMethodPhoneNumber: 'phone',
  ContactMethodPhoneNumberEmailHorizontalLayout: 'emailOrPhone',
  ContactMethodPhoneNumberEmailHorizontalLayoutAltContent1: 'emailOrPhone',
  ContactMethodPhoneNumberEmailVerticalLayout: 'emailOrPhone',
  FacebookSunset: 'facebook'
};

const UpsellCardButtonOrientations = {
  vertical: 'vertical',
  horizontal: 'horizontal'
};

const UpsellCardButtonGroup = {
  ContactMethodEmail: {
    primaryButton: {
      text: 'Action.SecureYourAccount',
      onClick: UpsellService?.renderEmailUpsell,
      buttonClickBtnLog: 'email'
    }
  },
  ContactMethodPhoneNumber: {
    primaryButton: {
      text: 'Action.AddPhone',
      onClick: UpsellService?.renderPhoneUpsell,
      buttonClickBtnLog: 'phone'
    }
  },
  ContactMethodPhoneNumberEmailHorizontalLayout: {
    primaryButton: {
      text: 'Action.AddPhoneShort',
      onClick: UpsellService?.renderPhoneUpsell,
      buttonClickBtnLog: 'phone'
    },
    secondaryButton: {
      text: 'Action.AddEmail',
      onClick: UpsellService?.renderEmailUpsell,
      buttonClickBtnLog: 'email'
    },
    buttonStackOrientation: UpsellCardButtonOrientations.horizontal
  },
  ContactMethodPhoneNumberEmailHorizontalLayoutAltContent1: {
    primaryButton: {
      text: 'Action.AddPhoneShort',
      onClick: UpsellService?.renderPhoneUpsell,
      buttonClickBtnLog: 'phone'
    },
    secondaryButton: {
      text: 'Action.AddEmail',
      onClick: UpsellService?.renderEmailUpsell,
      buttonClickBtnLog: 'email'
    },
    buttonStackOrientation: UpsellCardButtonOrientations.horizontal
  },
  ContactMethodPhoneNumberEmailVerticalLayout: {
    primaryButton: {
      text: 'Action.AddPhone',
      onClick: UpsellService?.renderPhoneUpsell,
      buttonClickBtnLog: 'phone'
    },
    secondaryButton: {
      text: 'Action.AddEmailAddress',
      onClick: UpsellService?.renderEmailUpsell,
      buttonClickBtnLog: 'email'
    },
    buttonStackOrientation: UpsellCardButtonOrientations.vertical
  },
  FacebookSunset: {
    primaryButton: {
      text: 'Action.SetPassword',
      onClick: FacebookSunsetService?.openFacebookSunsetModal,
      buttonClickBtnLog: 'setPassword'
    }
  }
};

const UpsellCardImageClass = {
  ContactMethodEmail: '',
  ContactMethodPhoneNumber: 'upsell-card-lock-icon-image',
  ContactMethodPhoneNumberEmailHorizontalLayout: 'upsell-card-lock-icon-image',
  ContactMethodPhoneNumberEmailHorizontalLayoutAltContent1: 'upsell-card-lock-icon-image',
  ContactMethodPhoneNumberEmailVerticalLayout: 'upsell-card-lock-icon-image',
  FacebookSunset: ''
};

export {
  UpsellCardType,
  UpsellCardContent,
  UpsellCardTitle,
  UpsellCardEventContext,
  UpsellCardEventSection,
  UpsellCardButtonOrientations,
  UpsellCardButtonGroup,
  UpsellCardImageClass
};
